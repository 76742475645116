<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-if="isadmin"
                            v-model="filter.network"
                            :items='networkNames'
                            item-text="name"
                            item-value="uuid"
                            label='Red'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                            v-text-field(slot='activator' :label="$t('Desde')" v-model='filter.from' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateStart = false') {{ $t('Cancelar') }}
                                v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{ $t('OK') }}
                        v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                            v-text-field(slot='activator' :label="$t('Hasta')" v-model='filter.to' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{ $t('Cancelar') }}
                                v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{ $t('OK') }}
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{ $t('Limpiar') }}
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="galleryColor"
                v-model="galleryMsj")
                    | {{ galleryText }}
                    v-btn(flat, dark, @click.native="galleryMsj = false") {{ $t('cerrar') }}
                v-container.grid-list-xl(fluid)
                    h1 {{ $t('Galería') }}
                    template(v-if="preload")
                        h3 {{ $t('Cargando...') }}
                    template(v-else)
                        v-layout(row wrap v-if="gallery.length")
                            v-flex(xs12 sm6 md4 lg3 xl2 v-for="gallery in gallery" :key="gallery.uuid")
                                .sheet.grey.lighten-3
                                    .sheet-options
                                        v-menu(left)
                                            v-icon(color='primary' dark slot='activator') more_vert
                                            v-list.sheet-menu
                                                v-list-tile.white--text(v-if="(profile.uuid == gallery.userid) || (isadmin || isgallery) || hasAction('Galería', 'Editar')" @click="$refs.manageGallery.open('edit', $t('Editar imagen'), gallery)")
                                                    v-list-tile-title {{ $t('Editar') }}
                                                v-list-tile.white--text(@click="openShare(gallery)")
                                                    v-list-tile-title {{ $t('Compartir') }}
                                                v-list-tile.white--text(v-if="(profile.uuid == gallery.userid) || (isadmin || isgallery) || hasAction('Galería', 'Eliminar')" @click="$refs.delete.open(gallery)")
                                                    v-list-tile-title {{ $t('Eliminar') }}
                                    .sheet-media
                                        img(:src="imgurl + gallery.image + '?time=' + gallery.time")
                                    .sheet-title
                                        h2.primary--text {{ gallery.title }}
                                    .sheet-content
                                        p {{ gallery.description }}
                            v-flex(xs12)
                                infinite-loading(:infinite="infinite" ref="infinite")
                        .not-found(v-else)
                            h3 {{ $t('No se encontraron imágenes') }}
                    v-btn(@click.native="$refs.manageGallery.open('add', $t('Agregar imagen'))" v-if="hasAction('Galería', 'Crear')" fixed dark fab bottom right color='primary')
                        v-icon add
                    v-dialog(v-model="shareModal" max-width="600")
                        v-form
                            v-card
                                v-card-title {{ $t('Compartir Imagen') }}
                                v-card-text
                                    p {{ $t('Comparte esta imagen a través de tus redes sociales') }}
                                    br
                                    .btns.btns-center
                                        v-btn.btn-whatsapp(@click.native="shareWp(shareGallery.uuid)" large color="secondary light--text") 
                                            img(src="/public/img/icons/whatsapp-icon.svg" :alt="$t('Whatsapp')")
                                            | {{ $t('Compartir en whatsapp') }}
                                        v-btn.btn-facebook(@click.native="shareFb(shareGallery.uuid)" small color="secondary light--text") 
                                            img(src="/public/img/icons/facebook-icon.svg" :alt="$t('Facebook')")
                                            | {{ $t('Compartir en facebook') }}
                                        v-btn.btn-twitter(@click.native="shareTw(shareGallery.uuid)" small color="secondary light--text") 
                                            img(src="/public/img/icons/twitter-icon.svg" :alt="$t('Twitter')")
                                            | {{ $t('Compartir en twitter') }}
                                        br
                                        a.mt-4(target="blank" :href="galleryurl + shareGallery.uuid"): b {{ galleryurl + shareGallery.uuid }}
                                v-card-actions
                                    .btns.btns-right
                                        v-btn(color="secondary" small @click.stop="shareModal = false") {{ $t('Cancelar') }}
            confirm(ref="delete" @action="deleteGallery" :title="$t('Eliminar imagen')" :message="$t('¿Estás seguro de eliminar este imagen de la galería?')")
            manage-gallery(ref="manageGallery" :isadmin="isadmin" @update="fetchGallery")
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import ManageGallery from 'components/ManageGallery'
    
    export default {
        components: {
            ManageGallery
        },
        mixins: [auth],
        metaInfo() {
            return {
                title: this.$t('Galería')
            }
        },
        data() {
            return {
                preload: false,
                pagination: {},
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query    : '',
                    network  : '',
                    from     : null,
                    to       : null
                },
                gallery      : [],
                networkNames : [],
                limit : this.$root.limit,
                offset: 0,
                shareModal: false,
                shareGallery: {},
                galleryMsj  : false,
                galleryColor: '',
                galleryText : '',
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            },
            imgurl(){
                return config.uploadimg
            },
            galleryurl(){
                return config.galleryurl
            }
        },
        watch: {
            filter: {
                handler(){
                    this.fetchGallery()
                },
                deep: true
            },
            profile: {
                handler(){
                    this.fetchNetworkNames()
                },
                deep: true
            }
        },
        created(){
            this.fetchGallery()
            this.filter = Object.assign({}, this.filterSchema)
        },
        methods: {
            shareFb(uuid){
                window.open(
                    'https://www.facebook.com/sharer/sharer.php?u=' + window.encodeURIComponent(this.galleryurl + uuid), 
                    'popup', 
                    'width=400, height=300'
                )
            },
            shareTw(uuid){
                window.open(
                    'https://twitter.com/intent/tweet?text=' + window.encodeURIComponent(this.shareGallery.description + ' ' + this.galleryurl + uuid), 
                    'popup',
                    'width=400, height=300'
                )
            },
            shareWp(uuid){
                let url = this.isMobile() ? 'whatsapp://' : 'https://api.whatsapp.com/'
                window.open(
                    url+'send?text=' + window.encodeURIComponent(this.shareGallery.description + ' ' + this.galleryurl + uuid),
                    'popup'
                )
            },
            fetchGallery(infinite = false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.preload = true
                    this.gallery = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    filter: JSON.stringify(this.filter)
                }

                this.$api(this, (xhr) => {
                    xhr.get('/gallery', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.gallery = this.gallery.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.gallery = (data.response ? data.data : [])
                        }
                    }).catch(() => {})
                })
            },

            infinite(){
                setTimeout(() => this.fetchGallery(true) , 500)
            },

            fetchNetworkNames(){

                let url = '/network?list=1'

                if(this.$root.profile.type == 2){
                    url = '/network/profile?list=1'
                }

                this.$api(this, (xhr) => {
                    xhr.get(url).then((r) => {

                        let data = r.data
                        if(data.response){
                            let networkNames = [[]].concat(data.data) 
                            this.networkNames = networkNames
                        }
                    }).catch(() => {})
                })
            },

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },

            openShare(data){
                this.shareGallery = Object.assign({}, data)
                this.shareModal = true
            },

            deleteGallery({uuid, userid}){

                if(!(this.profile.uuid == userid || this.isadmin || this.isgallery || this.hasAction('Galería', 'Eliminar'))){
                    return
                }

                this.$refs.delete.$emit('playLoader')
                this.$api(this, (xhr) => {
                    xhr.delete('/gallery?uuid=' + uuid).then((r) => {
                        
                        this.$refs.delete.$emit('stopLoader')
                        this.galleryMsj = true

                        let data = r.data

                        if(data.response){
                            this.galleryColor = 'success'
                            this.galleryText = data.message
                            this.$refs.delete.close()
                            this.fetchGallery()
                        }else{
                            this.galleryColor = 'error'
                            this.galleryText = data.message
                        }
                    }).catch(() => {})
                })
            }
        }
    }
    
</script>